<template>
  <div>
    <iframe id='prevewIframe' class="prevewIframe"
      frameborder='0'
      scrolling='no'
      style='overflow: hidden;'
      @load='iframeLoadHelper'
      sandbox='allow-scripts allow-same-origin allow-popups allow-top-navigation'
      data-alloy-tabstop='true'
      tabindex='-1'
      :srcdoc="`<!DOCTYPE html><html><head><base href=&quot;${baseUrl}&quot;>
        <link type=&quot;text/css&quot; rel=&quot;stylesheet&quot; href=&quot;${baseUrl}/js/tiny/skins/ui/oxide/content.min.css&quot;>
        <style type=&quot;text/css&quot;>
        @font-face {
            font-family: &quot;LIFEPLUS&quot;;
            font-style: normal;
            font-weight: 300;
            src: url(&quot;${baseUrl}/fonts/LIFEPLUS-Light.woff2&quot;) format(&quot;woff2&quot;),
            url(&quot;${baseUrl}/fonts/LIFEPLUS-Light.woff&quot;) format(&quot;woff&quot;);
        }
        @font-face {
            font-family: &quot;LIFEPLUS&quot;;
            font-style: normal;
            font-weight: 500;
            src: url(&quot;${baseUrl}/fonts/LIFEPLUS-Medium.woff2&quot;) format(&quot;woff2&quot;),
            url(&quot;${baseUrl}/fonts/LIFEPLUS-Medium.woff&quot;) format(&quot;woff&quot;);
        }
        @font-face {
            font-family: &quot;LIFEPLUS&quot;;
            font-style: normal;
            font-weight: 700;
            src: url(&quot;${baseUrl}/fonts/LIFEPLUS-Bold.woff2&quot;) format(&quot;woff2&quot;),
            url(&quot;${baseUrl}/fonts/LIFEPLUS-Bold.woff&quot;) format(&quot;woff&quot;);
        }
        @font-face {
            font-family: &quot;LIFEPLUS&quot;;
            font-style: normal;
            font-weight: bold;
            src: url(&quot;${baseUrl}/fonts/LIFEPLUS-Bold.woff2&quot;) format(&quot;woff2&quot;),
            url(&quot;${baseUrl}/fonts/LIFEPLUS-Bold.woff&quot;) format(&quot;woff&quot;);
        }
        body { font-family:LIFEPLUS;  font-size:14px;} 
        pre {white-space : normal} 
        img {max-width: 900px !important;} 
        .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: rgba(220,220,220);} 
        @media screen and (max-width:769px) { img {width:100% !important; height:auto !important;} } 
        .video-container {position: relative;padding-bottom: 56.25%;padding-top: 30px;height: 0;overflow: hidden;text-align: center;} 
        .video-container iframe,.video-container object,.video-container embed {position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
        </style>
        </head>
        <body id=&quot;tinymce&quot; class=&quot;mce-content-body &quot;>${description}
        <script>
          document.addEventListener &amp;&amp; document.addEventListener(&quot;click&quot;, function(e) {
            for (var elm = e.target; elm; elm = elm.parentNode) {
              if (elm.nodeName === &quot;A&quot; &amp;&amp; !(e.ctrlKey &amp;&amp; !e.altKey)) {
                e.preventDefault();
                if (elm.target) {
                  window.open(elm.href, elm.target);
                } else {
                  window.open(elm.href, &quot;_parent&quot;);
                }
              }
            }
          }, false);
        </script> </body></html>`"></iframe>
  </div>
</template>
<script>

export default {
  props: {
    description: {
      type: String,
      default: ''
    },
    paddingBottom: {
      type: Number,
      defualt: 100
    }
  },
  data() {
    return {
      convertDescription: null,
      baseUrl: window.location.origin
    }
  },
  mounted() {
    // this.convertDescription = this.description.replaceAll('"', '&quot;')
  },
  methods: {
    setHeight() {
      const objIframe = document.querySelector('#prevewIframe')
      if (objIframe) {
        const height = objIframe.contentWindow.document.body.scrollHeight + 20
        if (height > 300) {
          objIframe.style.height = `${height}px`
        } else {
          this.iframeLoadHelper()
        }
      } else {
        this.iframeLoadHelper()
      }
    },
    iframeLoadHelper() {
      setTimeout(() => { this.setHeight() }, 500)
    }
  }
}
</script>

<style>
.prevewIframe { width: 100% !important; height: 300px; min-height: 300px; overflow-y: hidden; -ms-overflow-style:hidden; }
.news_article iframe {width:100%;height:300px;}
</style>
