var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('iframe',{staticClass:"prevewIframe",staticStyle:{"overflow":"hidden"},attrs:{"id":"prevewIframe","frameborder":"0","scrolling":"no","sandbox":"allow-scripts allow-same-origin allow-popups allow-top-navigation","data-alloy-tabstop":"true","tabindex":"-1","srcdoc":`<!DOCTYPE html><html><head><base href="${_vm.baseUrl}">
      <link type="text/css" rel="stylesheet" href="${_vm.baseUrl}/js/tiny/skins/ui/oxide/content.min.css">
      <style type="text/css">
      @font-face {
          font-family: "LIFEPLUS";
          font-style: normal;
          font-weight: 300;
          src: url("${_vm.baseUrl}/fonts/LIFEPLUS-Light.woff2") format("woff2"),
          url("${_vm.baseUrl}/fonts/LIFEPLUS-Light.woff") format("woff");
      }
      @font-face {
          font-family: "LIFEPLUS";
          font-style: normal;
          font-weight: 500;
          src: url("${_vm.baseUrl}/fonts/LIFEPLUS-Medium.woff2") format("woff2"),
          url("${_vm.baseUrl}/fonts/LIFEPLUS-Medium.woff") format("woff");
      }
      @font-face {
          font-family: "LIFEPLUS";
          font-style: normal;
          font-weight: 700;
          src: url("${_vm.baseUrl}/fonts/LIFEPLUS-Bold.woff2") format("woff2"),
          url("${_vm.baseUrl}/fonts/LIFEPLUS-Bold.woff") format("woff");
      }
      @font-face {
          font-family: "LIFEPLUS";
          font-style: normal;
          font-weight: bold;
          src: url("${_vm.baseUrl}/fonts/LIFEPLUS-Bold.woff2") format("woff2"),
          url("${_vm.baseUrl}/fonts/LIFEPLUS-Bold.woff") format("woff");
      }
      body { font-family:LIFEPLUS;  font-size:14px;} 
      pre {white-space : normal} 
      img {max-width: 900px !important;} 
      .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: rgba(220,220,220);} 
      @media screen and (max-width:769px) { img {width:100% !important; height:auto !important;} } 
      .video-container {position: relative;padding-bottom: 56.25%;padding-top: 30px;height: 0;overflow: hidden;text-align: center;} 
      .video-container iframe,.video-container object,.video-container embed {position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
      </style>
      </head>
      <body id="tinymce" class="mce-content-body ">${_vm.description}
      <script>
        document.addEventListener && document.addEventListener("click", function(e) {
          for (var elm = e.target; elm; elm = elm.parentNode) {
            if (elm.nodeName === "A" && !(e.ctrlKey && !e.altKey)) {
              e.preventDefault();
              if (elm.target) {
                window.open(elm.href, elm.target);
              } else {
                window.open(elm.href, "_parent");
              }
            }
          }
        }, false);
      </script> </body></html>`},on:{"load":_vm.iframeLoadHelper}})])
}
var staticRenderFns = []

export { render, staticRenderFns }